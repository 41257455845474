// import external modules
import React, { useState, useEffect } from 'react';
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import Logo from '../components/layout/header/logo';

const GET_POST = gql`
query MyQuery ($slug: String = "") {
    project(where: {slug: $slug}) {
    id
    languages
    description {
        markdown
    }
    picture {
        url
    }
    title
    slug
    productiedatum
    icon {
        url
    }
    }
}
`;

export default function OneProjectPage() {
    const routeParams = useParams();
    const postId = routeParams.id;
  
    const { loading, error, data } = useQuery(GET_POST, {
      variables: { slug: postId },
    });
  
    useEffect(() => {
      // You can perform additional actions when data changes, if needed
      if (data) {
        console.log(data);
        // setPost(data.blog);
      }
    }, [data]);
  
    const project = data?.project; // Assuming 'blog' is the key in your GraphQL response
  
    return (
        <div className="h-[100vh] bg-purple-dark">
            <div className="ml-[7.5%] pt-[2rem] w-[20%]">
                <Logo />
            </div>
            <div className="pt-[3rem] h-[80vh]">
                {loading && <p>Loading...</p>}
                {error && <p>Error: {error.message}</p>}
                {project && (
                <>
                    <section className="relative flex max-w-[85%] h-full mx-auto ">
                        <div className="w-[50%] text-white-text flex flex-col gap-[2rem]">
                            <div>
                                <h2 className="text-white-text">{project.title}</h2>
                                <p className="text-gray-nav mt-[1rem]">{project.languages} - {project.productiedatum}</p>
                            </div>
                            <div className='w-[90%] scrollbar'>
                                <p className="text-sm leading-7">{project.description.markdown}</p>
                            </div>
                        </div>
                        <div className="w-[50%] border-4 border-purple-light">
                            <img className="h-full object-cover" src={project.picture.url} alt="" />
                        </div>
                        <div className="flex justify-center align-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-purple-light border-full h-[7rem] w-[7rem] rounded-full p-[1.2rem]">
                            <img className="w-full" src={project.icon.url} alt="" />
                        </div>
                    </section>
                </>
                )}
            </div>
        </div>
    );
  };
  