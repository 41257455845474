// import elements
import { Canvas } from '@react-three/fiber';

import GlobeElement from './globe.tsx';

function Globe() {

    return (
        <>
        <section>
                <div className='absolute w-full h-full z-[0]'>
                    <Canvas><GlobeElement /></Canvas>
                </div>
        </section>
        </>
    )
}

export default Globe;