import { gql, useQuery } from '@apollo/client';
import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const GET_ABOUT = gql`
    query MyQuery {
        abouts {
            title
            subtitle
            aboutMe
        }
    }
`;

export default function AboutText() {
    const container = useRef();

    const { loading, error, data } = useQuery(GET_ABOUT);

    useGSAP(() => {
        if (!loading && data) {
            // Set the initial state of the containers
            gsap.set(container.current, { opacity: 0, y: -100 });

            // Animate the containers to their final states with a delay
            gsap.to(container.current, {
                opacity: 1,
                y: 0,
                duration: 1,
                delay: .5, // Add a 1-second delay before the animation starts
                scrollTrigger: {
                    trigger: container.current,
                    start: "20% 100%",
                    toggleActions: "play reset play reverse",

                },
            });
        }
    }, [loading, data], { scope: container.current });

    // Check if data is still loading
    if (loading) {
        return <p>Loading...</p>;
    }
    // Check if there's an error
    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <>
            {data.abouts.map((about) =>
                <div ref={container} key={about.title}>
                    <h4  className='text-gray-nav uppercase'>{about.subtitle}</h4>
                    <h1 className='text-white-text mt-[1rem] mb-[2rem]'>{about.title}</h1>
                    <p className='text-gray-nav lg:w-[65%] w-[100%]'>{about.aboutMe}</p>
                </div>
            )}
        </>
    )
}
