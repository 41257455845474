// import external modules 
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache, from } from '@apollo/client';


// import custom modules
import { settings } from '../config';


// create a HTTP link to the GraphQl resource
const httpLink = new HttpLink({
    uri: settings.HYGRAPH_CONTENT_API_URL,
});

// create a apollo link that adds the eaccess token to the GraphQl requests
const apolloLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization: `Bearer ${settings.HYGRAPH_ACCESS_TOKEN}`,
        },
    }));
    return forward(operation);
});


// create apollo client and initialize it with the graphQl endpoint  
const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: from([apolloLink, httpLink]),
});

// create a custom HygraphProvider 

const HygraphProvider = ({ children }) => {
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    );
}

export default HygraphProvider;