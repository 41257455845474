import { Link } from "react-router-dom"
export default function TopButton() {
    return (
        <>
            <button className="fixed bg-purple-light right-[2rem] bottom-[2rem] p-[.5rem] rounded-full">
                <a href="#header">
                    <svg className="w-7 h-7" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12 3L4 12h5v8h6v-8h5L12 3z"></path>
                    </svg>
                </a>
            </button>
        </>
    )
}