import BlogText from "../components/layout/blogpage/text";
import BlogPosts from "../components/layout/blogpage/blogPosts";
import Logo from "../components/layout/header/logo";
import { Link } from "react-router-dom";


export default function Blog() {
    
    return (
        <>
            <div className="pb-[5rem] bg-purple-dark min-h-[100vh]">
                <div className="ml-[7.5%] pt-[2rem] w-[20%]">
                    <Logo />
                </div>
                <div className="max-w-[85%] mx-auto">
                    <BlogText />
                    <ul className="mt-[2rem] grid grid-cols-2 gap-7 w-full">
                        <BlogPosts />
                    </ul>
                </div>
            </div>
        </>
    )
}

