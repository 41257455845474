
export default function BlogText() {
    
    return (
        <>
            <div className="absolute bg-purple-card w-full h-[14rem] rounded-xl pl-[7.5%] pt-[4rem] text-white-text">
                <p className="text-gray-nav uppercase">About me</p>
                <h2 className="mt-[1rem]">Who am I.</h2>
            </div>
        </>
    )
}

