import { gql, useQuery } from "@apollo/client";
import { useRef } from "react";

const GET_CONTACTS = gql`
    query MyQuery {
        contacts {
            contactName
            linkTo
            logo {
                url
            }
        }
    }
`;

export default function FormContact() {
    const containerRefs = useRef([]);

    const { loading, error, data } = useQuery(GET_CONTACTS);

    // Check if data is still loading
    if (loading) {
        return <p>Loading...</p>;
    }
    // Check if there's an error
    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <>
            <section className="bg-purple-card rounded-xl w-[50%]">
                <div className="p-[2rem]">
                    <p className="text-gray-nav uppercase">get in touch</p>
                    <h2 className="text-white-text">Contact.</h2>
                    <div className="mt-4">
    {data.contacts.map((contacts, index) => (
        <div
            key={contacts.id}
            ref={(ref) => (containerRefs.current[index] = ref)}
            className=""
        >
            {index === data.contacts.length - 1 ? (
                <div className="flex items-center gap-8 mb-[1.25rem]">
                    <img className="w-[60px]" src={contacts.logo.url} alt={contacts.contactName} />
                    <h3 className="text-white-text text-[1rem]">{contacts.contactName}</h3>
                </div>
            ) : (
                <a
                    href={contacts.linkTo}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-8 mb-[1.25rem]"
                >
                    <img className="w-[60px]" src={contacts.logo.url} alt={contacts.contactName} />
                    <h3 className="text-white-text text-[1rem]">{contacts.contactName}</h3>
                </a>
            )}
        </div>
    ))}
</div>

                </div>
            </section>
        </>
    );
}
