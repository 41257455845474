import { gql, useQuery } from "@apollo/client";
import { useEffect, useRef } from "react";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"; // Import ScrollTrigger

gsap.registerPlugin(ScrollTrigger);

const GET_TECHNOLOGIES = gql`
    query MyQuery {
        technologies {
            title
            icon {
                url
            }
        }
    }
`;

export default function Technologies() {
    const containerRefs = useRef([]);

    const { loading, error, data } = useQuery(GET_TECHNOLOGIES);

    useGSAP(
        () => {
            if (!loading && data) {
                // Iterate through each container and set the initial state
                containerRefs.current.forEach((ref) => {
                    gsap.set(ref, { opacity: 0, x: -100 });
                });

                // Animate each container to its final state with a delay
                containerRefs.current.forEach((ref, index) => {
                    gsap.to(ref, {
                        opacity: 1,
                        x: 0,
                        duration: 1,
                        delay: index * 0.3, // Adjust the delay based on the index
                        scrollTrigger: {
                            trigger: ref,
                            start: "20% 100%",
                            toggleActions: "play reset play reverse",
                        },
                    });
                });
            }
        },
        [loading, data, ScrollTrigger], // Add ScrollTrigger as a dependency
        { scope: containerRefs.current }
    );

    // Check if data is still loading
    if (loading) {
        return <p>Loading...</p>;
    }
    // Check if there's an error
    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <>
            <section className="bg-purple-dark" id="technologies">
                <span className="tab">&nbsp;</span>
                <div className="flex justify-normal gap-[3rem] mt-[2rem]">
                    {data.technologies.map((technologies, index) => (
                        <div
                            key={index}
                            ref={(ref) => (containerRefs.current[index] = ref)}
                            className="w-[267px] green_gradient p-[3px] rounded-[20px] shadow-card"
                        >
                            <div className="bg-purple-card rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col">
                                <img className="w-[70%]" src={technologies.icon.url} alt={technologies.title} />
                                <h3 className="text-white-text text-[1rem]">{technologies.title}</h3>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
        </>
    );
}
