// External modules
import { Route, Routes } from 'react-router-dom';

// import the webfonts via fontsource
import '@fontsource/poppins/100.css';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import '@fontsource/poppins/900.css';

// import custom modules 

import { ROUTES } from '../src/app/routes/index';
import { HomePage, OneProjectPage, BlogPage, BlogDetail, Blog} from './app/pages';

import { HygraphProvider } from './app/services';


function App() {

  return (
    <HygraphProvider>
      <div className="app">
        <main className='{`main`}'>
          <Routes>

            <Route path={ROUTES.Home}>
              <Route index element={<HomePage />} />
              <Route path={ROUTES.OneWork} element={<OneProjectPage />} />
            </Route>

            <Route path={ROUTES.Blog}>
              <Route index element={<BlogPage />} />
              <Route path={ROUTES.OneBlog} element={<BlogDetail />} />
            </Route>
          </Routes>

        </main>
    
      </div>
    </HygraphProvider>
  );
}

export default App;