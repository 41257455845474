import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Logo from './logo';
import { ROUTES } from '../../../routes';

export default function Navigation() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Set scrolling to true when the user scrolls beyond 5rem
      setScrolling(window.scrollY > 120); // 1rem = 16px, so 5rem = 80px
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={`fixed z-[999999] pt-5 pb-5 w-full transition-all duration-500 ${scrolling ? 'bg-purple-dark' : ''}`}>
        <div className='flex justify-between items-center ml-[5%] w-[90%]'>
            <Logo />
            <ul className='flex gap-16 text-gray-nav'>
                <li className='hover:text-white-text'>
                    <a href={ROUTES.Me}>Me</a>
                </li>
                <li className='hover:text-white-text'>
                    <a href={ROUTES.About}>Overview</a>
                </li>
                <li className='hover:text-white-text'>
                    <a href={ROUTES.Work}>Work</a>
                </li>
                <li className='hover:text-white-text'>
                    <a href={ROUTES.Contact}>Contact</a>
                </li>
            </ul>
        </div>
    </nav>
  );
}
