import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes/index";


const GET_BLOGS = gql`
query MyQuery {
    blogs(orderBy: createdAt_DESC) {
      id
      message {
        markdown
      }
      name
      titleBlog
      slug
      publishedAt
      profilePicture {
        url
      }
    }
  }
`;


const formatCreatedAt = (publishedAt) => {
  const date = new Date(publishedAt);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export default function BlogPosts() {

  const { loading, error, data } = useQuery(GET_BLOGS);

    // Check if data is still loading
    if (loading) {
      return <p>Loading...</p>;
    }
    // Check if there's an error
    if (error) {
      return <p>Error: {error.message}</p>;
    }

    // Function to limit the number of words in a string and trigger a click on the post
    const limitWords = (text, limit) => {
      const words = text.split(' ');
      const limitedText = words.slice(0, limit).join(' ');
      return words.length > limit ? `${limitedText} ...` : limitedText;
    };
    return (
        <>
            {data.blogs.map((blog) =>
                        <li key={blog.id} className="text-white-text bg-purple-card rounded-2xl">
                            <Link to={`${ROUTES.Blog}/${blog.slug}`}>
                                <div className="p-[1.5rem]">
                                  <div className="flex justify-between items-center">
                                        <div className="flex gap-[1rem] items-center">
                                            <img className="w-[40px] h-[40px] object-cover rounded-full" src={blog.profilePicture.url} alt={blog.name} />
                                            <h3 className="text-white-text"><span className="text-purple-light">@</span> {blog.name}</h3>
                                        </div>
                                        <p className="text-gray-nav text-xs">{formatCreatedAt(blog.publishedAt)}</p>
                                    </div>
                                    <div className="">
                                        <p className="w-[95%]">{limitWords(blog.message.markdown, 20)}</p>
                                    </div>
                                </div>
                            </Link>
                        </li>
                    )}
        </>
    )
}

