
export default function BlogText() {
    
    return (
        <>
            <div className="text-white-text mt-[3rem]">
                <h2 className="">All blog posts.</h2>
            </div>
        </>
    )
}

