import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Logo from '../components/layout/header/logo';

const GET_BLOG = gql`
  query GetBlog($slug: String = "") {
    blog(where: { slug: $slug }) {
      id
      titleBlog
      message {
        markdown
      }
      createdAt
      name
      slug
      imageBlog {
        url
      }
      profilePicture {
        url
      }
    }
  }
`;

const formatCreatedAt = (createdAt) => {
  const date = new Date(createdAt);
  const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

const BlogDetail = () => {
  const routeParams = useParams();
  const postId = routeParams.id;

  const { loading, error, data } = useQuery(GET_BLOG, {
    variables: { slug: postId },
  });

  useEffect(() => {
    // You can perform additional actions when data changes, if needed
    if (data) {
      console.log(data);
      // setPost(data.blog);
    }
  }, [data]);

  const post = data?.blog; // Assuming 'blog' is the key in your GraphQL response

  return (
    <section className="h-[100vh]">

          {loading && <p>Loading...</p>}
          {error && <p>Error: {error.message}</p>}
          {post && (
            <>
            <div className="blog-post grid grid-cols-[60%,40%]">
              <div className="bg-purple-dark p-20 text-white-text pt-12 pb-12">
                      <Logo />
                      <div className="flex flex-col mt-[5rem] max-h-[68vh] justify-between">
                        <div>
                          <div className="flex mb-[2rem] items-center justify-between">
                            <h3 className="text-4xl text-purple-light">{post.titleBlog}</h3>
                            <p className="text-gray-nav">{formatCreatedAt(post.createdAt)}</p>
                          </div>
                          <div className="overflow-y-scroll max-h-[40vh]">
                            <p className="w-[95%]">{post.message.markdown}</p>
                          </div>
                        </div>
                        <div className="flex gap-[1rem] items-center mt-[2rem]">
                          <img className="w-[5rem] h-[5rem] object-cover rounded-full" src={post.profilePicture.url} alt={post.name} />
                          <h3 className="text-3xl">{post.name}</h3>
                        </div>
                      </div>
              </div>

              <div className="h-[100vh]">
                  <img className="object-cover h-full w-full" src={post.imageBlog.url} alt={post.titleBlog} />
              </div>
            </div>
            </>
          )}

    </section>
  );
};

export default BlogDetail;
