export default function Scroll() {
  return (
      <div className='absolute bottom-10 w-full flex justify-center items-center'>
        <a href="#about">
          <div className='w-[35px] h-[64px] rounded-3xl border-4 border-gray-nav flex justify-center items-start p-2'>
            <div className='w-3 h-3 rounded-full bg-gray-nav mb-1 scroll'></div>
          </div>
        </a>
      </div>
  );
}