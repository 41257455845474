import React, { useEffect, useMemo  } from 'react';
import { useGLTF } from '@react-three/drei';

import { useFrame } from '@react-three/fiber';
import { AnimationMixer } from 'three';
import { settings } from '../../../config';

// Assume your GLB file is in the public folder
const bureauPath = `${settings.BASE_PATH}/assets/3d/bureau.glb`;

export default function Bureau() {

  const { animations, scene }: any = useGLTF(bureauPath, true);

  const animationClip = animations[0];
  const mixer = useMemo(() => new AnimationMixer(scene), [scene]);

  useEffect(() => {
    const action = mixer.clipAction(animationClip);
    action.play();

    return () => {
      action.stop();
    }
  }, [mixer, animationClip]);

  useFrame((_, delta) => mixer.update(delta));
  
  return (
      <group scale={0.5} position={[1.2,-0.2,0]} rotation={[1.6, -1.6, -5]}>
        <directionalLight intensity={4.5}/>
        <primitive object={scene} />
      </group>
  );
};