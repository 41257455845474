import Header from "../components/layout/header/header"
import About from "./about"
import Work from "./work"
import Blog from "./blog"
import Contact from "./contact"

export default function HomePage() {
    return (
       <div className="">
            <Header />
            <Blog />
            <About />
            <Work />
            <Contact />
       </div> 
    )
}
