import { ROUTES } from "../../../routes";
import { Link } from 'react-router-dom';

import { settings } from "../../../config";

export default function Logo() {

  return (
    <div>
        <Link to={ROUTES.Home} className="flex gap-2 items-center">
        {/* Use the logo URL from the headers data */}
        <img className="h-10 w-12" alt="logo" src={`${settings.BASE_PATH}/assets/images/logo.png`} />
        {/* Use the title from the headers data */}
        <p className="text-white-text">Mauro Coone | Designer & Front end Developer</p>
        </Link>
    </div>
  );
}
